<template>
	<footer>
		<div class="fotter-area d-dark-bg">
			<div class="footer__top pt-80 pb-15">
				<div class="container">
					<div class="row">
						<div class="col-xl-5 col-lg-4 order-last-md d-none">
							<div class="row">
								<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
									<div class="footer__widget d-none">
										<div class="footer__widget-title">
											<h4>Customer Care</h4>
										</div>
										<div class="footer__widget-content">
											<div class="footer__link">
												<ul>
													<li><a href="faq">New Customers</a></li>
													<li><a href="faq">How to use Account</a></li>
													<li><a href="faq">Placing an Order</a></li>
													<li><a href="faq">Payment Methods</a></li>
													<li>
														<a href="faq">Delivery &amp; Dispatch</a>
													</li>
													<li><a href="faq">Problems with Order</a></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
									<div class="footer__widget d-none">
										<div class="footer__widget-title">
											<h4>Customer Service</h4>
										</div>
										<div class="footer__widget-content">
											<div class="footer__link">
												<ul>
													<li><a href="faq">Help Center</a></li>
													<li><a href="faq">Contact Us</a></li>
													<li><a href="faq">Report Abuse</a></li>
													<li><a href="faq">Submit a Dispute</a></li>
													<li><a href="faq">Policies &amp; Rules</a></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-12 col-lg-12 order-first-md text-center">
							<div class="footer__top-left">
								<div class="row">
									<div class="col-xl-7 col-lg-6 col-md-6 col-sm-6">
										<div class="row">
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-none">
												<div class="footer__widget">
													<div class="footer__widget-title">
														<h4>My Account</h4>
													</div>
													<div class="footer__widget-content">
														<div class="footer__link">
															<ul>
																<li><a href="faq">Product Support</a></li>
																<li><a href="checkout">Checkout</a></li>
																<li><a href="cart">Shopping Cart</a></li>
																<li><a href="wishlist">Wishlist</a></li>
																<li>
																	<a href="faq">Terms &amp; Conditions &amp;</a>
																</li>
																<li><a href="faq">Redeem Voucher</a></li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
												<div class="footer__widget">
													<div class="footer__widget-title">
														<h4>Quick Links</h4>
													</div>
													<div class="footer__widget-content">
														<div class="footer__link">
															<ul>
																<li>
																	<a href="/about">회사 소개</a>
																</li>
																<li>
																	<a href="/contact">회사 위치</a>
																</li>
																<!-- <li>
																	<a href="/contact">문의 하기</a>
																</li> -->
																<li><a href="/faq">FAQ</a></li>
																<!-- <li><a href="javascript:;">FAQ</a></li> -->
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 text-start">
										<div class="footer__widget">
											<div class="footer__widget-title mb-20">
												<h4 style="color: #fcbe00">학공소개</h4>
											</div>
											<div class="footer__widget-content">
												<p class="footer-text mb-35">
													학원인들이 국내 최저가로 양질의 교육 프로그램 및
													제품을 구매할 수 있도록 30년간 학원인들과 함께해온
													(주)해오름커뮤니케이션즈가 만든 국내 최초 '학'원에
													필요한 모든 '공'동구매 쇼핑몰입니다.
												</p>
												<div
													class="footer__hotline d-flex align-items-center mb-10 footer-pc"
												>
													<div class="icon mr-15">
														<i class="fal fa-headset"></i>
													</div>
													<div class="footer-text footer__widget-title">
														<pre
															style="
																color: #fcbe00;
																font-weight: bold;
																font-size: 17px;
															"
														><a href="javascirpt:;">{{
																this.contact
															}}</a></pre>
													</div>
												</div>
												<div
													class="footer__hotline d-flex align-items-center mb-10 footer-mobile"
												>
													<div class="icon mr-15">
														<i class="fal fa-headset"></i>
													</div>
													<div class="footer-text footer__widget-title">
														<h4></h4>
														<p
															style="
																color: #fcbe00;
																font-weight: bold;
																font-size: 16px;
															"
														>
															<a href="javascirpt:;">{{ this.contact }}</a>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer__bottom">
				<div class="container">
					<div class="footer__bottom-content pt-25 pb-45">
						<div class="row">
							<div class="col-xl-12">
								<div class="footer__links text-center mb-25 d-none">
									<p>
										<a href="faq">Online Shopping</a>
										<a href="faq">Promotions</a>
										<a href="faq">My Orders</a>
										<a href="faq">Help</a>
										<a href="faq">Customer Service</a>
										<a href="faq">Support</a>
										<a href="faq">Most Populars</a>
										<a href="faq">New Arrivals</a>
										<a href="faq">Special Products </a>
										<a href="faq">Manufacturers</a>
										<br />
										<a href="faq">Garden Equipments</a>
										<a href="faq">Powers And Hand Tools </a>
										<a href="faq">Utensil &amp; Gadget </a>
										<a href="faq">Printers</a>
										<a href="faq">Projectors</a>
										<a href="faq">Scanners</a>
										<a href="faq">Store</a>
										<a href="faq">Business</a>
									</p>
								</div>
								<div class="payment-image text-center mb-25 d-none">
									<a href="contact"
										><img src="/assets/img/payment/payment.png" alt=""
									/></a>
								</div>
								<div class="copy-right-area text-center">
									<p>
										{{ this.description }}
									</p>
									<p>
										Copyright (C) 2014 HaeorumCommunications CO.,LTD. All rights
										reserved.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { getCompanyInfo } from '@/api/company';

export default {
	data() {
		return {
			contact: '',
			description: '',
		};
	},
	created() {
		this.getCompany();
	},
	methods: {
		async getCompany() {
			try {
				const { data } = await getCompanyInfo();
				this.contact = data.contact;
				this.description = data.description;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>

<style></style>
