import { axiosInst } from './index';

//일반회원 가입
function registerUser(userData) {
	return axiosInst.post('v1/users/signup', userData);
}

//일반 로그인
function signinUser(userData) {
	return axiosInst.post('v1/users/login', userData);
}

//비회원 가입
function registerNoUser(userData) {
	return axiosInst.post('v1/nonusers/signup', userData);
}

//비회원 로그인
function signinNoUser(userData) {
	return axiosInst.post('v1/nonusers/login', userData);
}

//회원 정보 가져오기
function getUser(userId, userType) {
	return axiosInst.get('v1/users/getUsersInfo', {
		params: { userId, userName: '', phone: '', userType },
	});
}

//회원 정보 수정
function modifyUser(userData) {
	return axiosInst.post('v1/users/modified', userData);
}

//회원 탈퇴
function revokedUser() {
	return axiosInst.post('users/revokeduser');
}

//회원정보 조회
function getUserInfo() {
	return axiosInst.get('v1/users/me');
}

//회원 정보 수정
function resetPwdUser(userData) {
	return axiosInst.post(
		`v1/users/reset-password?name=${userData.name}&phone=${userData.phone}&userId=${userData.userId}`,
	);
}

export {
	registerUser,
	signinUser,
	registerNoUser,
	signinNoUser,
	getUser,
	modifyUser,
	revokedUser,
	getUserInfo,
	resetPwdUser,
};
