import { axiosInst } from './index';
// import store from './index';

//기본 주소 조회
function getAddress() {
	return axiosInst.get('/v1/addr/getaddr');
}

//전체 주소 조회
function getAllAddress(userId) {
	return axiosInst.get('/v1/addr/getaddrlist', { params: { userId } });
}

//주소 추가
function addAddress(addData) {
	return axiosInst.post('/v1/addr/add', addData);
}

//주소 업데이트
function updateAddress(addData) {
	return axiosInst.post('v1/addr/modified', addData);
}

//주소 삭제
function removeAddress(addressId) {
	return axiosInst.post(`v1/addr/deladdr?seqAddr=${addressId}`);
}

//회원가입시 주소 추가
function addDefaultAddress(addData) {
	return axiosInst.post('/v1/addr/defaultAdd', addData);
}

export {
	getAddress,
	getAllAddress,
	addAddress,
	updateAddress,
	removeAddress,
	addDefaultAddress,
};
