function saveWishlistStorage(value) {
	localStorage.setItem('wish_list', JSON.stringify(value));
}

function saveCartStorage(value) {
	localStorage.setItem('cart', JSON.stringify(value));
}

function saveBuyProductStorage(value) {
	localStorage.setItem('buyProduct', JSON.stringify(value));
}
function saveIsBuyProductStorage(value) {
	localStorage.setItem('isBuyProduct', JSON.stringify(value));
}

function saveSawStorage(value) {
	localStorage.setItem('saw', JSON.stringify(value));
}

function getWishlistStorage() {
	const wishList = localStorage.getItem('wish_list');
	return JSON.parse(wishList);
}

function getCartStorage() {
	const cart = localStorage.getItem('cart');
	return JSON.parse(cart);
}

function getBuyProductStorage() {
	const buyProduct = localStorage.getItem('buyProduct');
	return JSON.parse(buyProduct);
}

function getIsBuyProductStorage() {
	const isBuyProduct = localStorage.getItem('isBuyProduct');
	return JSON.parse(isBuyProduct);
}

function getSawStorage() {
	const saw = localStorage.getItem('saw');
	return JSON.parse(saw);
}

export {
	saveWishlistStorage,
	saveCartStorage,
	saveBuyProductStorage,
	saveIsBuyProductStorage,
	saveSawStorage,
	getWishlistStorage,
	getCartStorage,
	getBuyProductStorage,
	getIsBuyProductStorage,
	getSawStorage,
};
