import Vue from 'vue';
import Vuex from 'vuex';
import {
	getAuthFromCookie,
	getUserFromCookie,
	getUserInfoFromCookie,
	saveAuthToCookie,
	saveUserToCookie,
	saveUserInfoToCookie,
} from '@/utils/cookies';

import {
	saveWishlistStorage,
	saveCartStorage,
	saveBuyProductStorage,
	saveIsBuyProductStorage,
	saveSawStorage,
	getWishlistStorage,
	getCartStorage,
	getBuyProductStorage,
	getIsBuyProductStorage,
	getSawStorage,
} from '@/utils/storage';
import { signinUser, getUserInfo } from '@/api/users';
import { getAddress, getAllAddress } from '@/api/address';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		userId: getUserFromCookie() || '',
		token: getAuthFromCookie() || '',
		userInfo: getUserInfoFromCookie() || '',
		address: '',
		allAddress: [],
		product: {},
		redirectBuyProduct: getBuyProductStorage() || {},
		wishlist: getWishlistStorage() || [],
		saw: getSawStorage() || [],
		cart: getCartStorage() || [],
		cartTotal: 0,
		isRedirectBuyProduct: getIsBuyProductStorage() || false,
	},
	getters: {
		isLogin(state) {
			return state.userId !== '';
		},
		getUserInfo(state) {
			return state.userInfo;
		},
		getAddress(state) {
			return state.address;
		},
		allAddress(state) {
			return state.allAddress;
		},
		getWishlist(state) {
			return state.wishlist;
		},
		getProduct(state) {
			return state.product;
		},
		getIsBuyProduct(state) {
			return state.isRedirectBuyProduct;
		},
		getCart(state) {
			return state.cart;
		},
		getCartTotal(state) {
			const cart = state.cart;
			const total = cart.reduce((sum, v) => {
				if (v.groupBuying > 0) {
					return sum + v.endPrice;
				}
				return sum + v.sellPrice;
			}, 0);
			return total;
		},
		getOrderTotal(state) {
			const cart = state.cart;
			const total = cart.reduce((sum, v) => {
				if (v.groupBuying > 0) {
					return sum + v.endPrice * v.qty + v.opPrice * v.qty;
				}
				return sum + v.sellPrice * v.qty + v.opPrice * v.qty;
			}, 0);
			return total;
		},
		getBuyProductOrder(state) {
			return state.redirectBuyProduct;
		},
		getSaw(state) {
			return state.saw;
		},
	},
	mutations: {
		setUserId(state, userId) {
			state.userId = userId;
		},
		setUserInfo(state, userInfo) {
			state.userInfo = userInfo;
		},
		clearUserId(state) {
			state.userId = '';
		},
		setToken(state, token) {
			state.token = token;
		},
		setAddress(state, address) {
			state.address = address;
		},
		setAllAddress(state, address) {
			state.allAddress = address;
		},
		setProudct(state, product) {
			state.product = product;
		},
		setBuyProudct(state, product) {
			state.redirectBuyProduct = product;
			saveBuyProductStorage(state.redirectBuyProduct);
		},
		setIsBuyProudct(state, isProduct) {
			state.isRedirectBuyProduct = isProduct;
			saveIsBuyProductStorage(state.isRedirectBuyProduct);
		},
		setWishlist(state, prodt) {
			let isProduct = false;
			const isKey = Object.keys(prodt).includes('product');

			if (isKey) {
				var { product, qty, opName, opPrice } = prodt;

				isProduct = window._.some(state.wishlist, {
					productId: product.productId,
				});

				if (isProduct) {
					// alert('이미 추가한 상품 입니다.');
					return;
				}

				product.qty = qty || 1;
				product.opName = opName || '';
				product.opPrice = opPrice || 0;
				// state.cart.push(product);
				// saveCartStorage(state.cart);
				state.wishlist.push(product);
				saveWishlistStorage(state.wishlist);
			} else {
				var prod = prodt;
				isProduct = window._.some(state.wishlist, {
					productId: prod.productId,
				});
				if (isProduct) {
					// alert('이미 추가한 상품 입니다.');
					return;
				}
				prod.qty = qty;
				prod.opName = opName;
				prod.opPrice = opPrice;
				state.wishlist.push(prod);
				saveWishlistStorage(state.wishlist);
			}
		},
		setResetWishlist(state, productId) {
			state.wishlist = window._.remove(state.wishlist, item => {
				return item.productId != productId;
			});
			saveWishlistStorage(state.wishlist);
		},
		setCart(state, prodt) {
			let isProduct = false;

			const isKey = Object.keys(prodt).includes('product');
			console.log(isKey);

			if (isKey) {
				var { product } = prodt;

				isProduct = window._.some(state.cart, {
					productId: product.productId,
				});

				if (isProduct) {
					alert('이미 장바구니에 있는 상품입니다.');
					return false;
				}
				state.cart.push(product);
			} else {
				var prod = prodt;
				isProduct = window._.some(state.cart, {
					productId: prod.productId,
				});
				if (isProduct) {
					alert('이미 장바구니에 있는 상품입니다.');
					return false;
				}
				state.cart.push(prod);
			}
			saveCartStorage(state.cart);

			if (!confirm(`장바구니에 상품이 담겼습니다. 장바구니로 이동할까요?`)) {
				return false;
			}
			location.href = '/cart';
		},
		setResetCart(state, optionId) {
			window._.remove(state.cart, item => {
				item.addedOptions = item.addedOptions.filter(
					option => option.optionId !== optionId,
				);
				return item.addedOptions.length < 1;
			});

			saveCartStorage(state.cart);
		},
		setSaw(state, prodt) {
			let isProduct = false;
			var prod = prodt;

			isProduct = window._.some(state.saw, { productId: prod.productId });
			if (isProduct) {
				return;
			}
			state.saw.push(prod);
			saveSawStorage(state.saw);
		},
		setResetSaw(state, productId) {
			state.saw = window._.remove(state.saw, item => {
				return item.productId != productId;
			});
			saveSawStorage(state.saw);
		},
		setQtyCart(state, product) {
			const cart = state.cart;
			cart.map(v => {
				if (v.productId === product.productId) {
					v.qty = product.qty;
					return v;
				}
				return v;
			});
			saveCartStorage(cart);
		},
	},
	actions: {
		async SIGNIN_USER({ commit }, userData) {
			const { data } = await signinUser(userData);
			if (data.success === 'false') return data.success;
			const token = data.token;
			commit('setToken', token);
			commit('setUserId', data.users.userId);
			commit('setUserInfo', data.users);
			saveAuthToCookie(token);
			saveUserToCookie(data.users.userId);
			data.users.name = encodeURI(data.users.name);
			saveUserInfoToCookie(data.users);
			return data.success;
		},
		async SIGNIN_SNS_USER({ commit }) {
			const recOauth = window.$reciveResult;
			if (!recOauth) return;
			const token =
				'Bearer' + ' ' + recOauth.substring(recOauth.indexOf('=') + 1);
			commit('setToken', token);
			const { data } = await getUserInfo();
			this.state.userId = data.userId;
			commit('setUserId', data.userId);
			commit('setUserInfo', data);
			saveAuthToCookie(token);
			saveUserToCookie(data.userId);
			saveUserInfoToCookie(data);
			return !token ? false : true;
		},
		async GET_ADDRESS({ commit }) {
			const { data } = await getAddress();
			commit('setAddress', data);
			return data;
		},
		async GET_ALL_ADDRESS({ commit }) {
			const { data } = await getAllAddress(this.state.userId);
			commit('setAllAddress', data);
			return data;
		},
		SET_PRODUCT({ commit }, product) {
			commit('setProudct', product);
			return;
		},
		SET_BUY_REDIRECT_PRODUCT({ commit }, product) {
			commit('setBuyProudct', product);
			return;
		},
		SET_IS_BUY_REDIRECT_PRODUCT({ commit }, isProduct) {
			commit('setIsBuyProudct', isProduct);
			return;
		},
		SET_WISHLIST({ commit }, product) {
			commit('setWishlist', product);
			return;
		},
		SET_REMOVE_WISHITEM({ commit }, productId) {
			commit('setResetWishlist', productId);
			return;
		},
		SET_CART({ commit }, product) {
			commit('setCart', product);
			return;
		},
		SET_REMOVE_CART({ commit }, optionId) {
			commit('setResetCart', optionId);
			return;
		},
		SET_SAW({ commit }, product) {
			commit('setSaw', product);
			return;
		},
		SET_REMOVE_SAW({ commit }, productId) {
			commit('setResetSaw', productId);
			return;
		},
		SET_QTY_CART({ commit }, product) {
			commit('setQtyCart', product);
			return;
		},
	},
});
