import axios from 'axios';
import { setInterceptors } from './common/interceptors';

// 초기화
function createInstance() {
	const instance = axios.create({
		baseURL: process.env.VUE_APP_API_URL,
	});
	return setInterceptors(instance);
}

export const axiosInst = createInstance();
