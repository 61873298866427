<template>
	<ul>
		<li v-for="(item, i) in categories" :key="i">
			<a href="#"
				>{{ item.mainCategory }}
				<i class="far fa-angle-down"></i>
			</a>
			<ul class="submenu">
				<li v-for="subItem in subCategories" :key="subItem.category_id">
					<a
						:href="`/shop/${subItem.categoryId}`"
						v-if="subItem.parentId === item.parentId && subItem.status == '1'"
						>{{ subItem.subCategory }}</a
					>
				</li>
			</ul>
		</li>
		<!-- <li v-for="(item, i) in categories" :key="i">
			<a href="#"
				>{{ item.mainCategory }}
				<i class="far fa-angle-down"></i>
			</a>
			<ul class="submenu">
				<li v-for="subItem in subCategories" :key="subItem.category_id">
					<a
						:href="`/shop/${subItem.categoryId}`"
						v-if="subItem.parentId === item.parentId && subItem.status == '1'"
						>{{ subItem.subCategory }}</a
					>
				</li>
			</ul>
		</li> -->
		<!-- <li>
			새 상품<i class="far fa-angle-down"></i>
			<ul class="submenu">
				<li><a href="shop">Home Appliances</a></li>
				<li>
					<a href="shop">Technology</a>
					<ul class="submenu">
						<li><a href="shop">Storage Devices</a></li>
						<li><a href="shop">Monitors</a></li>
						<li><a href="shop">Laptops</a></li>
					</ul>
				</li>
				<li><a href="shop">Office Equipments</a></li>
			</ul>
		</li>
		<li>TV &amp; 오디오</li>
		<li><a href="shop">가전기기 &amp; 디지털</a></li>
		<li class="d-laptop-none">
			<a href="shop">패션 &amp; 의류</a>
		</li>
		<li class="d-laptop-none">
			<a href="shop">쥬얼리 &amp; 시계</a>
		</li>
		<li><a href="shop">헬스 &amp; 뷰티</a></li> -->
	</ul>
</template>

<script>
import { getAllCategory } from '@/api/categories';

export default {
	data() {
		return {
			categories: [],
			subCategories: [],
		};
	},
	created() {
		this.seletedCategory();
	},
	methods: {
		async seletedCategory() {
			try {
				const { data } = await getAllCategory();
				const cats = data.filter(cat => cat.status == '1');
				this.categories = window._.uniqBy(cats, 'mainCategory');
				this.subCategories = cats;
			} catch (error) {
				alert(error);
				// console.log(error);
			}
		},
	},
};
</script>

<style></style>
