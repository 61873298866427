import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/',
			redirect: '/index',
		},
		//메인 페이지
		{
			path: '/index',
			component: () => import('@/views/IndexPage.vue'),
		},
		//상품 페이지
		{
			path: '/product/:id',
			component: () => import('@/views/ProductPage.vue'),
		},
		//샵 페이지
		{
			path: '/shop',
			component: () => import('@/views/ShopPage.vue'),
		},
		//카테고리id로 샵 페이지
		{
			path: '/shop/:id',
			component: () => import('@/views/ShopPage.vue'),
		},
		//카테고리id로 샵 페이지
		{
			path: '/shop/type/:type',
			component: () => import('@/views/ShopPage.vue'),
		},
		//키워드로 샵 페이지
		{
			path: '/shop/keyword/:title',
			component: () => import('@/views/ShopPage.vue'),
		},
		//장바구니 페이지
		{
			path: '/wishlist',
			component: () => import('@/views/WishlistPage.vue'),
		},
		//결제 페이지
		{
			path: '/checkout',
			component: () => import('@/views/CheckoutPage.vue'),
		},
		//카트 페이지
		{
			path: '/cart',
			component: () => import('@/views/CartPage.vue'),
		},
		//카트 페이지
		{
			path: '/saw',
			component: () => import('@/views/SawPage.vue'),
		},
		//주소 페이지
		{
			path: '/address',
			component: () => import('@/views/AddressPage.vue'),
		},
		//주소 등록 및 변경 페이지
		{
			path: '/address/:seq',
			component: () => import('@/views/AddressFormPage.vue'),
		},
		//계정 페이지
		{
			path: '/account',
			component: () => import('@/views/AccountPage.vue'),
		},
		//회원가입 페이지
		{
			path: '/register',
			component: () => import('@/views/RegisterPage.vue'),
		},
		//로그인 페이지
		{
			path: '/login',
			component: () => import('@/views/LoginPage.vue'),
		},
		//비밀번호 초기화 페이지
		{
			path: '/reset',
			component: () => import('@/views/ResetPage.vue'),
		},
		//FAQ 페이지
		{
			path: '/faq',
			component: () => import('@/views/FaqPage.vue'),
		},
		//문의 페이지
		{
			path: '/contact',
			component: () => import('@/views/ContactPage.vue'),
		},
		//소개 페이지
		{
			path: '/about',
			component: () => import('@/views/AboutPage.vue'),
		},
		//FAQ 페이지
		{
			path: '/myreview',
			component: () => import('@/views/MyreviewPage.vue'),
		},
		//FAQ 페이지
		{
			path: '/qna',
			component: () => import('@/views/QnaPage.vue'),
		},
		//공지사항 페이지
		{
			path: '/notice',
			component: () => import('@/views/NoticePage.vue'),
		},
		//일반게시판 페이지
		{
			path: '/board',
			component: () => import('@/views/BoardPage.vue'),
		},
		//자료실 페이지
		{
			path: '/rfboard',
			component: () => import('@/views/RfBoardPage.vue'),
		},
		//자료실 페이지
		{
			path: '/orders',
			component: () => import('@/views/OrdersPage.vue'),
		},
		//결제 완료 페이지
		{
			path: '/complete',
			component: () => import('@/views/CompletePaymentPage.vue'),
		},
		//에러 페이지
		{
			path: '*',
			component: () => import('@/views/404Page.vue'),
		},
	],
});
