var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"fotter-area d-dark-bg"},[_c('div',{staticClass:"footer__top pt-80 pb-15"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-xl-12 col-lg-12 order-first-md text-center"},[_c('div',{staticClass:"footer__top-left"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-xl-5 col-lg-6 col-md-6 col-sm-6 text-start"},[_c('div',{staticClass:"footer__widget"},[_vm._m(2),_c('div',{staticClass:"footer__widget-content"},[_c('p',{staticClass:"footer-text mb-35"},[_vm._v(" 학원인들이 국내 최저가로 양질의 교육 프로그램 및 제품을 구매할 수 있도록 30년간 학원인들과 함께해온 (주)해오름커뮤니케이션즈가 만든 국내 최초 '학'원에 필요한 모든 '공'동구매 쇼핑몰입니다. ")]),_c('div',{staticClass:"footer__hotline d-flex align-items-center mb-10 footer-pc"},[_vm._m(3),_c('div',{staticClass:"footer-text footer__widget-title"},[_c('pre',{staticStyle:{"color":"#fcbe00","font-weight":"bold","font-size":"17px"}},[_c('a',{attrs:{"href":"javascirpt:;"}},[_vm._v(_vm._s(this.contact))])])])]),_c('div',{staticClass:"footer__hotline d-flex align-items-center mb-10 footer-mobile"},[_vm._m(4),_c('div',{staticClass:"footer-text footer__widget-title"},[_c('h4'),_c('p',{staticStyle:{"color":"#fcbe00","font-weight":"bold","font-size":"16px"}},[_c('a',{attrs:{"href":"javascirpt:;"}},[_vm._v(_vm._s(this.contact))])])])])])])])])])])])])]),_c('div',{staticClass:"footer__bottom"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"footer__bottom-content pt-25 pb-45"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"copy-right-area text-center"},[_c('p',[_vm._v(" "+_vm._s(this.description)+" ")]),_c('p',[_vm._v(" Copyright (C) 2014 HaeorumCommunications CO.,LTD. All rights reserved. ")])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-5 col-lg-4 order-last-md d-none"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"},[_c('div',{staticClass:"footer__widget d-none"},[_c('div',{staticClass:"footer__widget-title"},[_c('h4',[_vm._v("Customer Care")])]),_c('div',{staticClass:"footer__widget-content"},[_c('div',{staticClass:"footer__link"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("New Customers")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("How to use Account")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Placing an Order")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Payment Methods")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Delivery & Dispatch")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Problems with Order")])])])])])])]),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"},[_c('div',{staticClass:"footer__widget d-none"},[_c('div',{staticClass:"footer__widget-title"},[_c('h4',[_vm._v("Customer Service")])]),_c('div',{staticClass:"footer__widget-content"},[_c('div',{staticClass:"footer__link"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Help Center")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Contact Us")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Report Abuse")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Submit a Dispute")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Policies & Rules")])])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-7 col-lg-6 col-md-6 col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-6 d-none"},[_c('div',{staticClass:"footer__widget"},[_c('div',{staticClass:"footer__widget-title"},[_c('h4',[_vm._v("My Account")])]),_c('div',{staticClass:"footer__widget-content"},[_c('div',{staticClass:"footer__link"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Product Support")])]),_c('li',[_c('a',{attrs:{"href":"checkout"}},[_vm._v("Checkout")])]),_c('li',[_c('a',{attrs:{"href":"cart"}},[_vm._v("Shopping Cart")])]),_c('li',[_c('a',{attrs:{"href":"wishlist"}},[_vm._v("Wishlist")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Terms & Conditions &")])]),_c('li',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Redeem Voucher")])])])])])])]),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-6 col-sm-6"},[_c('div',{staticClass:"footer__widget"},[_c('div',{staticClass:"footer__widget-title"},[_c('h4',[_vm._v("Quick Links")])]),_c('div',{staticClass:"footer__widget-content"},[_c('div',{staticClass:"footer__link"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"/about"}},[_vm._v("회사 소개")])]),_c('li',[_c('a',{attrs:{"href":"/contact"}},[_vm._v("회사 위치")])]),_c('li',[_c('a',{attrs:{"href":"/faq"}},[_vm._v("FAQ")])])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__widget-title mb-20"},[_c('h4',{staticStyle:{"color":"#fcbe00"}},[_vm._v("학공소개")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon mr-15"},[_c('i',{staticClass:"fal fa-headset"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon mr-15"},[_c('i',{staticClass:"fal fa-headset"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__links text-center mb-25 d-none"},[_c('p',[_c('a',{attrs:{"href":"faq"}},[_vm._v("Online Shopping")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Promotions")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("My Orders")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Help")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Customer Service")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Support")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Most Populars")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("New Arrivals")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Special Products ")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Manufacturers")]),_c('br'),_c('a',{attrs:{"href":"faq"}},[_vm._v("Garden Equipments")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Powers And Hand Tools ")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Utensil & Gadget ")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Printers")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Projectors")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Scanners")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Store")]),_c('a',{attrs:{"href":"faq"}},[_vm._v("Business")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment-image text-center mb-25 d-none"},[_c('a',{attrs:{"href":"contact"}},[_c('img',{attrs:{"src":"/assets/img/payment/payment.png","alt":""}})])])
}]

export { render, staticRenderFns }