function saveAuthToCookie(value) {
	document.cookie = `gonggu_auth=${value}; max-age=3600;`;
}

function saveUserToCookie(value) {
	document.cookie = `gonggu_user=${value}; max-age=3600;`;
}

function saveUserInfoToCookie(value) {
	document.cookie = `gonggu_userInfo=${JSON.stringify(value)}; max-age=3600;`;
}

function getAuthFromCookie() {
	return document.cookie.replace(
		/(?:(?:^|.*;\s*)gonggu_auth\s*=\s*([^;]*).*$)|^.*$/,
		'$1',
	);
}

function getUserFromCookie() {
	return document.cookie.replace(
		/(?:(?:^|.*;\s*)gonggu_user\s*=\s*([^;]*).*$)|^.*$/,
		'$1',
	);
}

function getUserInfoFromCookie() {
	const info = document.cookie.replace(
		/(?:(?:^|.*;\s*)gonggu_userInfo\s*=\s*([^;]*).*$)|^.*$/,
		'$1',
	);

	if (!info) return;
	const parse = JSON.parse(info);
	parse.name = decodeURI(parse.name);
	// info.name = decodeURI(parse.name);
	// return !info ? '' : JSON.parse(parse);
	return !info ? '' : parse;
}

function deleteCookie(value) {
	document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export {
	saveAuthToCookie,
	saveUserToCookie,
	saveUserInfoToCookie,
	getAuthFromCookie,
	getUserFromCookie,
	getUserInfoFromCookie,
	deleteCookie,
};
