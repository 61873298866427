<template>
	<div>
		<!-- header-start -->
		<header class="header d-blue-bg">
			<div class="header-top">
				<div class="container">
					<div class="header-inner">
						<div class="row align-items-center">
							<div class="col-xl-6 col-lg-7">
								<div class="header-inner-start d-none">
									<div class="header__currency border-right">
										<div class="s-name">
											<span>Language: </span>
										</div>
										<select>
											<option>English</option>
											<option>Deutsch</option>
											<option>Français</option>
											<option>Espanol</option>
										</select>
									</div>
									<div class="header__lang border-right">
										<div class="s-name">
											<span>Currency: </span>
										</div>
										<select>
											<option>USD</option>
											<option>EUR</option>
											<option>INR</option>
											<option>BDT</option>
											<option>BGD</option>
										</select>
									</div>
									<div class="support d-none d-sm-block">
										<p>
											Need Help?
											<a href="tel:+001123456789">+001 123 456 789</a>
										</p>
									</div>
								</div>
							</div>
							<div class="col-xl-6 col-lg-5 d-none d-lg-block">
								<div class="header-inner-end text-md-end">
									<div class="ovic-menu-wrapper">
										<ul>
											<li v-if="!isUserLogin">
												<a href="/register">회원가입</a>
											</li>
											<li><a href="/about">회사 소개</a></li>
											<li><a href="/contact">회사 위치</a></li>
											<!-- <li><a href="/contact">문의 하기</a></li> -->
											<li><a href="/faq">FAQ</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="header-mid">
				<div class="container">
					<div class="heade-mid-inner">
						<div class="row align-items-center">
							<div class="col-xl-3 col-lg-3 col-md-4 col-sm-4">
								<div class="header__info">
									<div class="logo">
										<a href="/" class="logo-image">
											<!-- <img src="/assets/img/logo/logo1.svg" alt="logo" -->
											<img src="/assets/img/logo/logo.png" alt="logo"
										/></a>
									</div>
								</div>
							</div>
							<div class="col-xl-5 col-lg-4 d-none d-lg-block">
								<div class="header__search">
									<form action="#">
										<div class="header__search-box">
											<input
												class="search-input"
												type="text"
												placeholder=""
												v-model="keyword"
											/>
											<button
												class="button"
												type="submit"
												@click.prevent="searchMove"
											>
												<i class="far fa-search"></i>
											</button>
										</div>
										<!-- <div class="header__search-cat">
										<CategorySelect></CategorySelect>
									</div> -->
									</form>
								</div>
							</div>
							<div class="col-xl-4 col-lg-5 col-md-8 col-sm-8 p-0">
								<div class="header-action">
									<div
										class="block-userlink block-userlink-login d-lg-block"
										v-if="!isUserLogin"
									>
										<a class="icon-link" href="/login">
											<i class="flaticon-user"></i>
											<span class="text sub">
												<!-- <span class="sub">로그인</span> -->
												로그인
											</span>
										</a>
									</div>
									<div
										class="block-userlink block-userlink-login main-menu d-lg-block"
										v-else
									>
										<nav>
											<ul>
												<li>
													<a class="icon-link" href="javascript:;">
														<i class="flaticon-user"></i>
														<span class="text">
															<span class="sub-login">{{
																`${$store.state.userInfo.name}님 환영합니다.`
															}}</span>
															마이 페이지
															<ul class="megamenu-1">
																<li>
																	<a href="javascript:;">정보관리</a>
																	<ul class="mega-item">
																		<li><a href="/account">개인 정보</a></li>
																		<!-- <li>
																		<a
																			href="javascript:;"
																			onclick="alert('준비중 입니다.');"
																			>멤버쉽 관리</a
																		>
																	</li> -->
																		<!-- <li>
																		<a
																			href="javascript:;"
																			onclick="alert('준비중 입니다.');"
																			>결제 관리</a
																		>
																	</li> -->
																		<li>
																			<a href="/address">배송지 관리</a>
																		</li>
																	</ul>
																</li>
																<li>
																	<a href="javascript:;">활동 관리</a>
																	<ul class="mega-item">
																		<li>
																			<a href="/myreview">리뷰목록</a>
																		</li>
																		<li>
																			<a href="/qna">1:1 문의(Q&A)</a>
																		</li>
																	</ul>
																</li>
																<li>
																	<a href="javascript:;">구매 관리</a>
																	<ul class="mega-item">
																		<li>
																			<a href="/cart">장바구니</a>
																		</li>
																		<li>
																			<a href="/orders">주문 목록</a>
																		</li>
																		<!-- <li>
																		<a
																			href="javascript:;"
																			onclick="alert('준비중 입니다.');"
																			>영수증 조회</a
																		>
																	</li> -->
																	</ul>
																</li>
																<li>
																	<a href="javascript:;" @click="logout"
																		>로그아웃</a
																	>
																</li>
															</ul>
														</span>
													</a>
												</li>
											</ul>
										</nav>
									</div>
									<div class="block-wishlist action">
										<!-- <a class="icon-link" href="/wishlist">
										<i class="flaticon-heart"></i>
										<span class="text sub"> 위시리스트 </span>
									</a> -->
										<a class="icon-link" href="/wishlist">
											<i class="flaticon-heart"></i>
											<!-- <span class="count">{{
											`${$store.state.wishlist.length}`
										}}</span> -->
											<span class="text sub">
												<!-- <span class="sub">찜상품</span> -->
												찜상품
											</span>
										</a>
									</div>
									<div class="block-cart action">
										<!-- <a class="icon-link" href="/cart">
										<i class="fal fa-shopping-cart"></i>
										<span class="text sub">
											{{ getTotal > 0 ? getTotal : 0 | currencyStamp }} 원
										</span>
									</a> -->
										<a class="icon-link" href="/cart">
											<!-- <i class="flaticon-shopping-bag"></i> -->
											<i class="fal fa-shopping-cart"></i>
											<!-- <span class="count">{{
											`${$store.state.cart.length}`
										}}</span> -->
											<span class="text sub">
												<span class="sub">장바구니</span>
												<!-- {{ getTotal > 0 ? getTotal : 0 | currencyStamp }} 원 -->
											</span>
										</a>
										<div class="cart d-none">
											<div class="cart__mini">
												<ul>
													<li>
														<div class="cart__title">
															<h4>장바구니</h4>
															<span>{{
																`(${$store.state.cart.length} 개)`
															}}</span>
														</div>
													</li>
													<li v-for="(item, i) in getCart" :key="i">
														<div
															class="cart__item d-flex justify-content-between align-items-center"
														>
															<div class="cart__inner d-flex">
																<div class="cart__thumb">
																	<a :href="`/product/${item.productId}`">
																		<img
																			:src="`${$imgPath}/${item.mainImg}`"
																			alt=""
																		/>
																	</a>
																</div>
																<div class="cart__details">
																	<h6>
																		<a :href="`/product/${item.productId}`">
																			{{ item.title }}
																		</a>
																	</h6>
																	<div
																		class="cart__price"
																		v-if="item.groupBuying > 0"
																	>
																		<h6>
																			{{ item.endPrice | currencyStamp }}원 x
																			{{ item.qty }}
																		</h6>
																		<span>
																			{{
																				(item.endPrice * item.qty)
																					| currencyStamp
																			}}원
																		</span>
																	</div>
																	<div class="cart__price" v-else>
																		<h6>
																			{{ item.sellPrice | currencyStamp }}원 x
																			{{ item.qty }}
																		</h6>
																		<span>
																			{{
																				(item.sellPrice * item.qty)
																					| currencyStamp
																			}}원
																		</span>
																	</div>
																</div>
															</div>
															<div class="cart__del">
																<a
																	href="javascript:;"
																	@click="removeCartItem(item.productId)"
																	><i class="fal fa-times"></i
																></a>
															</div>
														</div>
													</li>
													<li>
														<div
															class="cart__sub d-flex justify-content-between align-items-center"
														>
															<h6>합계</h6>
															<span class="cart__sub-total"
																>{{
																	getTotal > 0 ? getTotal : 0 | currencyStamp
																}}
																원</span
															>
														</div>
													</li>
													<li>
														<a href="/cart" class="wc-cart mb-10"
															>장바구니 보기</a
														>
														<!-- <a
														href="javascript:;"
														@click="checkout"
														class="wc-checkout"
														>결제하기</a
													> -->
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="block-copy action">
										<a class="icon-link" href="/saw">
											<i class="fal fa-copy"></i>

											<span class="text sub">
												<span class="sub">내가 본 상품</span>
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="header__bottom">
				<div class="container">
					<div class="row g-0 align-items-center">
						<div class="col-lg-3">
							<div class="cat__menu-wrapper side-border d-none d-lg-block">
								<div class="cat-toggle">
									<button type="button" class="cat-toggle-btn cat-toggle-btn-1">
										<i class="fal fa-bars"></i> 카테고리
									</button>
									<div class="cat__menu">
										<nav id="mobile-menu" style="display: block">
											<CategoryMenu></CategoryMenu>
										</nav>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-3">
							<div
								class="header__bottom-left d-flex d-xl-block align-items-center"
							>
								<div class="side-menu d-lg-none mr-20">
									<button
										type="button"
										class="side-menu-btn offcanvas-toggle-btn"
									>
										<i class="fas fa-bars"></i>
									</button>
								</div>
								<div class="main-menu d-none d-lg-block">
									<nav>
										<ul>
											<li>
												<a href="javascript:;"
													>프랜차이즈몰 <i class="far fa-angle-down"></i
												></a>
												<ul class="submenu">
													<li><a href="/shop/63">프랜차이즈</a></li>
													<li>
														<a href="/shop/64">교재</a>
													</li>
													<li><a href="/shop/65">컨텐츠</a></li>
												</ul>
											</li>
											<li><a href="/shop/66">공동구매상품</a></li>
											<li><a href="/shop/67">고객관리툴몰</a></li>
											<li><a href="/shop/68">노하우몰 </a></li>
											<li>
												<a href="javascript:;"
													>Community <i class="far fa-angle-down"></i
												></a>
												<ul class="submenu">
													<li><a href="/notice">공지사항</a></li>
													<li>
														<a href="/rfboard">자료실</a>
													</li>
													<li><a href="/board">자유게시판</a></li>
													<li><a href="/faq">FAQ</a></li>
												</ul>
											</li>
											<!-- <li class="has-mega">
											<a href="#">Community <i class="far fa-angle-down"></i></a>
											<div class="mega-menu">
												<div class="container container-mega">
													<ul>
														<li>
															<ul>
																<li class="title">
																	<a href="shop">SHOP LAYOUT</a>
																</li>
																<li><a href="shop">Pagination</a></li>
																<li><a href="shop">Ajax Load More</a></li>
																<li><a href="shop">Infinite Scroll</a></li>
																<li><a href="shop">Sidebar Right</a></li>
																<li><a href="shop">Sidebar Left</a></li>
															</ul>
														</li>
														<li>
															<ul>
																<li class="title">
																	<a href="shop">SHOP PAGES</a>
																</li>
																<li><a href="shop">List View</a></li>
																<li><a href="shop">Small Products</a></li>
																<li><a href="shop">Large Products</a></li>
																<li><a href="shop">Shop — 3 Items</a></li>
																<li><a href="shop">Shop — 4 Items</a></li>
																<li><a href="shop">Shop — 5 Items</a></li>
															</ul>
														</li>
														<li>
															<ul>
																<li class="title">
																	<a href="shop">PRODUCT LAYOUT</a>
																</li>
																<li>
																	<a href="shop">Description Sticky</a>
																</li>
																<li>
																	<a href="shop">Product Carousel</a>
																</li>
																<li><a href="shop">Gallery Modern</a></li>
																<li><a href="shop">Thumbnail Left</a></li>
																<li><a href="shop">Thumbnail Right</a></li>
																<li>
																	<a href="shop">Thumbnail Botttom</a>
																</li>
															</ul>
														</li>
														<li>
															<ul>
																<li class="title">
																	<a href="shop">Basketball</a>
																</li>
																<li>
																	<a href="shop">East Hampton Fleece</a>
																</li>
																<li>
																	<a href="shop">Faxon Canvas Low</a>
																</li>
																<li>
																	<a href="shop">Habitasse Dictumst</a>
																</li>
																<li>
																	<a href="shop">Kaoreet Lobortis</a>
																</li>
																<li><a href="shop">NikeCourt Zoom</a></li>
																<li>
																	<a href="shop">NikeCourts Air Zoom</a>
																</li>
															</ul>
														</li>
														<li>
															<ul>
																<li class="title">
																	<a href="shop">Basketball</a>
																</li>
																<li>
																	<a href="shop">East Hampton Fleece</a>
																</li>
																<li>
																	<a href="shop">Faxon Canvas Low</a>
																</li>
																<li>
																	<a href="shop">Habitasse Dictumst</a>
																</li>
																<li>
																	<a href="shop">Kaoreet Lobortis</a>
																</li>
																<li><a href="shop">NikeCourt Zoom</a></li>
																<li>
																	<a href="shop">NikeCourts Air Zoom</a>
																</li>
															</ul>
														</li>
													</ul>
												</div>
												<div class="offer mt-40">
													<p>
														<b>30% OFF</b> the shipping of your first order with
														the code: <b>DUKA-SALE30</b>
													</p>
												</div>
											</div>
										</li> -->
										</ul>
									</nav>
								</div>
							</div>
						</div>
						<!-- <div class="col-lg-3 col-md-6 col-9 d-none">
							<div class="shopeing-text text-sm-end">
								<p>Spend $120 more and get free shipping!</p>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</header>
		<!-- header-end -->

		<!-- offcanvas area start -->
		<div class="offcanvas__area">
			<div class="offcanvas__wrapper">
				<div class="offcanvas__close">
					<button class="offcanvas__close-btn" id="offcanvas__close-btn">
						<i class="fal fa-times"></i>
					</button>
				</div>
				<div class="offcanvas__content">
					<div class="offcanvas__logo mb-40">
						<a href="/">
							<!-- <img src="assets/img/logo/logo-white.png" alt="logo" /> -->
						</a>
					</div>
					<div class="offcanvas__search mb-25">
						<form action="#">
							<input type="text" placeholder="상품 검색" v-model="keyword" />
							<button type="submit" @click.prevent="searchMove">
								<i class="far fa-search"></i>
							</button>
						</form>
					</div>
					<div class="mobile-menu fix"></div>
					<div class="offcanvas__action"></div>
				</div>
			</div>
		</div>

		<div class="body-overlay"></div>
	</div>
	<!-- offcanvas area end -->
</template>

<script>
// import CategorySelect from '@/components/common/CategorySelect.vue';
import CategoryMenu from '@/components/common/CategoryMenu.vue';

export default {
	components: {
		CategoryMenu,
	},
	data() {
		return {
			cartList: this.$store.state.cart,
			keyword: '',
		};
	},
	computed: {
		isUserLogin() {
			return this.$store.getters.isLogin;
		},
		getTotal() {
			return this.$store.getters.getOrderTotal;
		},
		getCart() {
			return this.$store.getters.getCart;
		},
	},
	methods: {
		removeCartItem(productId) {
			if (!productId) return;
			this.$store.dispatch('SET_REMOVE_CART', productId);
		},
		searchMove() {
			location.href = `/shop/keyword/${this.keyword}`;
		},
		logout() {
			this.$cookies.remove('gonggu_user');
			this.$cookies.remove('gonggu_auth');
			this.$cookies.remove('gonggu_userInfo');
			localStorage.clear();
			alert('로그아웃 되었습니다.');
			location.href = '/';
		},
		checkout() {
			this.$store.dispatch('SET_IS_BUY_REDIRECT_PRODUCT', false);
			location.href = '/checkout';
		},
	},
};
</script>

<style></style>
