import Vue from 'vue';
import App from './App.vue';
import router from '@/routes/index';
import store from '@/store/index';
import vueCookies from 'vue-cookies';
import VSwitch from 'v-switch-case';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import _ from 'lodash';
import * as filters from '@/utils/filters';

Vue.config.productionTip = false;
Vue.prototype._ = _;
Vue.prototype.$imgPath = process.env.VUE_APP_API_IMG_PATH;
Vue.prototype.$imgBannerPath = process.env.VUE_APP_API_IMG_BANNER_PATH;
Vue.prototype.$imgBoardPath = process.env.VUE_APP_API_IMG_BOARD_PATH;
Vue.prototype.$child;
Vue.prototype.$reciveResult;
Vue.prototype.$payResult;

Vue.use(vueCookies);
Vue.use(VSwitch);
Vue.use(VueGoodTablePlugin);

const KAKAKO_API_KEY = '16051ebb8519abd9f0d2e0466e284db3';
window.Kakao.init(KAKAKO_API_KEY);

Vue.$cookies.config('1h');

Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key]);
});

new Vue({
	render: h => h(App),
	router,
	store,
}).$mount('#app');

window.reciveOauth = function (result) {
	window.$reciveResult = result;
};

window.payComplete = function (result) {
	window.$payResult = result;
};
